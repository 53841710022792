<template>
    <main v-if="isMobile()" class="mb-content" @click="aboutShow=false,businessShow=false">
        <div class="home-banner">
            <div class="swiper" v-if="bannerArr.length>0">
                <swiper
                    :disableOnInteraction="false"
                    :navigation="false"
                    :slides-per-view="1"
                    :autoplay="{delay: 3000,disableOnInteraction:false}"
                    :space-between="50"
                    effect='fade'
                    :pagination="true"
                    :speed='1500'
                >
                    <swiper-slide class="flex align_center justify_center" v-for="item in bannerArr" :key="item.bannerUrl">
                        <div class="img"><img draggable="false" :src="item.bannerUrl" alt=""></div>
                    </swiper-slide>
                </swiper>
                <div class="swiper-pagination"></div>
            </div>
        </div>
        <div class="tabs-content" id="Tab" ref="tab">
            <div :class="['tabs', tabShow?'tab-fixed':'']">
                <div class="item1 active" @click="goto('Home')">首页</div>
                <div class="item2">
                    <span @click.stop="aboutShow = !aboutShow">关于中科信恒</span><i></i>
                    <div class="down-list" v-if="aboutShow">
                        <div @click.stop="goto('Introduce')">集团简介</div>
                        <div @click.stop="goto('Course')">集团发展</div>
                    </div>
                </div>
                <div class="item3">
                    <span @click.stop="businessShow = !businessShow">核心业务</span><i></i>
                    <div class="down-list down-list1" v-if="businessShow">
                        <div @click.stop="goto('Environment')">环保科技产业</div>
                        <div @click.stop="goto('Education')">教育科技产业</div>
                    </div>
                </div>
                <div class="item3" @click="goto('Dynamic')">新闻动态</div>
            </div>
        </div>
        <div class="introduce">
            <h2>集团简介</h2>
            <div class="line"> GROUP PROFILE </div>
        </div>
        <div class="introduce-html" v-html="introduceObj?introduceObj.content:''"></div>
        <p class="introduce-more" @click="goto('About')">>>>了解更多</p>
        <div class="news-line">新闻动态</div>
        <div class="news-list">
            <div v-for="item in newsList" :key="item.id" @click="goto('Detail', item)">
                <div class="item">
                    <div class="img">
                        <img :src="item.titleImg" alt="">
                    </div>
                    <div class="right">
                        <p class="title">{{item.title}}</p>
                        <p class="time">集团新闻 {{item.createTime.substring(0,10)}}</p>
                    </div>
                </div>
            </div>
            <div class="news-more" @click="goto('News')">更多</div>
        </div>
    </main>
    <main v-else class="pc-content">
        <div class="home-banner">
            <div class="swiper" v-if="bannerArr.length>0">
                <swiper
                    :disableOnInteraction="false"
                    :navigation="false"
                    :slides-per-view="1"
                    :autoplay="{delay: 3000,disableOnInteraction:false}"
                    :space-between="50"
                    effect='fade'
                    :pagination="true"
                    :speed='1500'
                >
                    <swiper-slide class="flex align_center justify_center" v-for="item in bannerArr" :key="item.bannerUrl">
                        <div class="img"><img draggable="false" :src="item.bannerUrl" alt=""></div>
                    </swiper-slide>
                </swiper>
                <div class="swiper-pagination"></div>
            </div>
        </div>
        <div class="content">
            <div class="profile">
                <div class="profile-left">
                    <h3>集团介绍</h3>
                    <p>GROUP PROFILE</p>
                    <div v-html="introduceObj?introduceObj.content:''"></div>
                    <!-- <div class="text">
                        广西中科信恒投资集团有限公司（以下简称中科信恒集团）成立于2015年，立足南宁，旗下拥有10家全资或控股子公司，注册资金5000万，总资产达20亿元。<br>
                    </div>
                    <div class="text">
                        中科信恒集团是在国家倡导推广政府与社会资本合作建设公共基础设施或公共物品和服务（PPP）的大背景下孕育而生，6年来集团始终聚焦国家新兴产业布局，充分发挥具有竞争优势的商务资源、投融资能力、项目全过程咨询和运营服务能力，以投资为纽带，资源整合为路径，创新多产业综合类PPP为模式，提供PPP项目全流程一体化服务，在广西、山西成功打造多个涵盖环保、教育、科技、文旅等综合类PPP示范项目，立志为提高社会公共产品和服务的效率，提高政府财政资源效率，促进政府职能转型多做贡献，真正实现政企共享共赢。
                    </div> -->
                </div>
                <img draggable="false" class="profile-right" :src="introduceObj?introduceObj.url:''" alt="">
            </div>
            <div class="industry">
                <div class="industry-content" ref="title">
                    <div class="title-copy">
                        <transition name="title-slide-fade">
                            <div v-if="titleShow">
                                <div class="title1">核心产业 <span>/ CORE INDUSTRY</span> </div>
                                <!-- <p class="title2">三产一体 全产业链生态运营</p> -->
                            </div>
                        </transition>
                    </div>
                    <div class="img-arr">
                        <div class="img img1" @mouseenter="hover.img1=true" @mouseleave="hover.img1=false" @click="goto('Environment')">
                            <transition name="search-slide-fade">
                                <div class="search" v-if="hover.img1"></div>
                            </transition>
                            <img draggable="false" src="../../../assets/images/home/course1.png" alt="">
                        </div>
                        <div class="img img2">
                            <div @mouseenter="hover.img2=true" @mouseleave="hover.img2=false" @click="goto('Education')">
                                <transition name="search-slide-fade">
                                    <div class="search" v-if="hover.img2"></div>
                                </transition>
                                <img draggable="false" src="../../../assets/images/home/course2.png" alt="">
                            </div>
                            <div @mouseenter="hover.img3=true" @mouseleave="hover.img3=false" @click="goto('Education')">
                                <transition name="search-slide-fade">
                                    <div class="search" v-if="hover.img3"></div>
                                </transition>
                                <img draggable="false" src="../../../assets/images/home/course3.png" alt="">
                            </div>
                        </div>
                        <div class="img img3">
                            <div @mouseenter="hover.img4=true" @mouseleave="hover.img4=false" @click="goto('Education')">
                                <transition name="search-slide-fade">
                                    <div class="search" v-if="hover.img4"></div>
                                </transition>
                                <img draggable="false" src="../../../assets/images/home/course5.png" alt="">
                            </div>
                            <div @mouseenter="hover.img5=true" @mouseleave="hover.img5=false" @click="goto('Education')">
                                <transition name="search-slide-fade">
                                    <div class="search" v-if="hover.img5"></div>
                                </transition>
                                <img draggable="false" src="../../../assets/images/home/course4.png" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="news">
                <h3>集 团 新 闻</h3>
                <p class="title1">GROUP NEWS</p>
                <div class="news-list">
                    <div class="news-item" v-for="(item,i) in newsList" :key="item.titleImg" @click="goto('Detail', item)">
                        <div v-if="i<6">
                            <div class="img">
                                <img draggable="false" :src="item.titleImg" alt="">
                            </div>
                            <p class="title">{{item.title}}</p>
                            <p class="text">盛夏红城，芒香四溢！天赋百色美，地道芒果香。为促进青年创业创新，鼓励青年参与百色芒果网络销售，拓宽芒果销售渠道，推动乡村振兴，促进农民增收，由中国青年创业就业基金会指导，共青团广西区委员会、自治区商务厅、自治区人社厅、自治区教育厅、中共百色市委、百色市人民政府决定联合举办2021年全国青年（大学生）乡村振兴百色芒果网络营销创新创业大赛。8月20日，2021年全国青年（大学生）乡村振兴百色芒果...</p>
                            <p class="time">{{item.createTime.substring(0,10)}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import SwiperCore, {Navigation, Autoplay, EffectFade, Pagination} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/vue';
require('swiper/swiper.min.css')
require('swiper/components/navigation/navigation.scss')
require('swiper/components/effect-fade/effect-fade.scss')
require('swiper/components/pagination/pagination.scss')
import { ref } from '@vue/reactivity';
import { onMounted, onUnmounted } from '@vue/runtime-core';
import { useRouter } from 'vue-router';
import { getBanner, getNews, getIntroduce } from '@/api/home'

SwiperCore.use([Navigation, Autoplay, EffectFade, Pagination])

export default {
    name: 'Home',
    components: {
      Swiper,
      SwiperSlide
    },
    setup() {
        const titleShow = ref(false)
        const title = ref(null)
        const tab = ref(null)
        const tabShow = ref(false)
        const hg = ref(0)
        const hover = ref(null)
        const router = useRouter()
        const bannerArr = ref([])
        const newsList = ref([])
        const introduceObj = ref('')
        const aboutShow = ref(false)
        const businessShow = ref(false)

        // 判断浏览器函数
        const isMobile = () => {
            if(window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
                return true; // 移动端
            }else{
                return false; // PC端
            }
        }

        const getBannerList = async() => {
            let data = await getBanner()
            if(data.status === 200) {
                bannerArr.value = data.data.data
            }
        }
        getBannerList()

        const getNewsList = async() => {
            let data = await getNews()
            if(data.status === 200) {
                newsList.value = data.data.data
            }
        }
        getNewsList()

        const getIntroduceList = async() => {
            let data = await getIntroduce({placeType: 0})
            if(data.status === 200) {
                introduceObj.value = data.data.data
            }
        }
        getIntroduceList()

        hover.value = {img1: false, img2: false, img3: false, img4: false, img5: false}

        const scrollFun = () => {
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
            if(scrollTop - hg.value > 0) {
                titleShow.value = true
            }
        }

        const scrollFunTab = () => {
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
            if(scrollTop - hg.value > 0) {
                tabShow.value = true
            }else {
                tabShow.value = false
            }
        }

        const goto = (name, item) => {
            sessionStorage.setItem('detail', JSON.stringify(item))
            router.push({
                name: name
            })
        }

        onMounted(() => {
            if(isMobile()) {
                setTimeout(() => {
                    hg.value = tab.value.getBoundingClientRect().top
                    window.addEventListener("scroll", scrollFunTab, true); //监听滚动事件
                }, 500);
            }else {
                setTimeout(() => {
                    let titleHg = title.value.getBoundingClientRect().top + 150
                    let winHg = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
                    let height = titleHg - winHg;
                    hg.value = height;
                    window.addEventListener("scroll", scrollFun, true); //监听滚动事件
                }, 500);
            }
        })

        onUnmounted(() => {
            window.removeEventListener('scroll',scrollFun)
        })

        return {
            titleShow,
            title,
            tab,
            tabShow,
            hover,
            goto,
            bannerArr,
            newsList,
            introduceObj,
            isMobile,
            aboutShow,
            businessShow
        }
    }
}
</script>
<style lang="scss" scoped>
    .pc-content {
        width: 100%;
        .home-banner {
            min-height: 570px;
        }
        .swiper {
            background: linear-gradient(0deg, rgba(144, 202, 248, 1) 78.0701754385965%,rgba(10, 123, 216, 1) 92.10526315789474%) !important;
        }
        .img {
            width: 100%;
            text-align: center;
            img {
                // height: 800px;
								width: 100%;
                // width: auto;
                vertical-align: bottom;
            }
        }
        .content {
            width: 100%;
        }
        .profile {
            display: flex;
            text-align: center;
            width: 1330px;
            margin: 0 auto;
            margin-top: 50px;
            min-height: 700px;
            .profile-left {
                display: inline-block;
                width: 665px;
                padding-left: 15px;
                margin-right: 50px;
                margin-top: 120px;
                text-align: left;
                h3 {
                    font-size: 30px;
                    font-weight: normal;
                }
                p {
                    color: #999999;
                    margin-top: 10px;
                    position: relative;
                    padding-bottom: 30px;
                    margin-bottom: 25px;
                }
                p::after {
                    content: '';
                    border-bottom: 3px solid #4bb344;
                    width: 40px;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                }
                .text {
                    text-indent: 28px;
                    line-height: 2.2em;
                    font-size: 18px;
                    color: #494949;
                }
            }
            .profile-right {
                width: 570px;
                text-align: left;
            }
        }
        .industry {
            margin-top: 100px;
            background: url(../../../assets/images/bg2.jpg) repeat top ,transparent;
            background-size: 100%;
            padding-top: 60px;
            padding-bottom: 110px;
            .industry-content {
                width: 1330px;
                margin: 0 auto;
            }
            .title-copy {
                height: 66px;
                margin-bottom: 50px;
            }
            .title-slide-fade-enter-active {
                transition:  all .5s ease-in;
            }
            .title-slide-fade-leave-active {
                transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
            }

            .title-slide-fade-enter-from,
            .title-slide-fade-leave-to {
                transform: translateY(50px);
                opacity: 0;
            }
            .title1 {
                color: #fff;
                font-size: 26px;
                text-align: center;
                span {
                    font-size: 16px;
                    margin-left: 5px;
                }
            }
            .title2 {
                color: #fff;
                text-align: center;
                margin-top: 10px;
                margin-bottom: 50px;
            }
            .img-arr {
                display: flex;
                .search {
                    content: '';
                    position: absolute;
                    left: 50%;
                    right: 50%;
                    top: 50%;
                    bottom: 50%;
                    transform: translate(-50%, -50%);
                    width: 80px;
                    height: 80px;
                    border-radius: 50%;
                    background: url(../../../assets/images/home/search.png) no-repeat;
                    background-size: 100%;
                }
                .img1 {
                    width: 460px;
                    height: 670px;
                    margin-right: 35px;
                    cursor: pointer;
                    position: relative;
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
                .img2 {
                    width: 405px;
                    height: 670px;
                    margin-right: 35px;
                    img {
                        width: 100%;
                        height: 318px;
                    }
                    >div {
                        cursor: pointer;
                        position: relative;
                    }
                    >div:first-child {
                        margin-bottom: 30px;
                    }
                }
                .img3 {
                    width: 410px;
                    height: 584px;
                    >div {
                        cursor: pointer;
                        position: relative;
                    }
                    >div:first-child {
                        margin-bottom: 30px;
                    }
                    img {
                        width: 100%;
                        height: 318px;
                    }
                }
                .search-slide-fade-enter-active {
                    transition:  all .3s linear;
                }
                .search-slide-fade-leave-active {
                    transition:  all .3s linear;
                }

                .search-slide-fade-enter-from,
                .search-slide-fade-leave-to {
                    opacity: 0;
                }
            }
        }
        .news {
            width: 1330px;
            margin: 0 auto;
            h3 {
                font-size: 30px;
                text-align: center;
                margin-top: 70px;
                margin-bottom: 10px;
            }
            .title1 {
                text-align: center;
                margin-bottom: 70px;
            }
            .news-list {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                padding: 5px;
                .news-item {
                    width: calc(33.33% - 24px);
                    box-shadow: 0 0 15px 0 rgb(0 0 0 / 10%);
                    border-radius: 6px;
                    overflow: hidden;
                    background: #fff;
                    margin: 0 12px;
                    margin-bottom: 30px;
                    .img {
                        height: 190px;
                        width: 100%;
                        overflow: hidden;
                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .title {
                        font-size: 18px;
                        margin: 15px 0;
                        display: -webkit-box;
                        overflow: hidden;
                        padding: 0 15px;
                        text-overflow: ellipsis;
                        -webkit-box-orient: vertical;
                        word-break: break-word;
                        word-wrap: break-word;
                        -webkit-line-clamp: 1;
                    }
                    .text {
                        font-size: 16px;
                        color: #878787;
                        line-height: 26px;
                        padding: 0 15px;
                        display: -webkit-box;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        -webkit-box-orient: vertical;
                        word-break: break-word;
                        word-wrap: break-word;
                        -webkit-line-clamp: 2;
                    }
                    .time {
                        color: #999;
                        margin-top: 18px;
                        padding: 0 15px;
                        padding-bottom: 15px;
                    }
                }
            }
        }
    }
    .mb-content {
        .home-banner {
           .swiper {
                background: linear-gradient(0deg, rgba(144, 202, 248, 1) 78.0701754385965%,rgba(10, 123, 216, 1) 92.10526315789474%) !important;
            }
            .img {
                width: 100%;
                text-align: center;
                img {
                    // height: 30vh;
                    width: 100%;
                    vertical-align: bottom;
                }
            }
        }
        .tabs-content {
            height: 50px;
            .tabs {
                display: flex;
                font-size: 0.28rem;
                >div {
                    text-align: center;
                    line-height: 50px;
                    position: relative;
                    .down-list {
                        position: absolute;
                        bottom: calc(-35px - 0.9rem);
                        left: 50%;
                        transform: translate(-50%, 0);
                        width: 2rem;
                        z-index: 10;
                        line-height: normal;
                        padding-bottom: 0.3rem;
                        border-radius: 0.1rem;
                        box-shadow: 0 0 0.75rem 0 rgb(0 0 0 / 10%);
                        background: #fff;
                        >div {
                            margin-top: 0.3rem;
                        }
                    }
                    .down-list1 {
                        width: 2.5rem;
                    }
                }
                .active {
                    border-bottom: 2px solid #25477f;
                }
                .item1 {width: 15%;}
                .item2 {width: 33%;}
                .item3 {width: 26%;}
                i {
                    display: inline-block;
                    width: 0.34rem;
                    height: 0.36rem;
                    vertical-align: middle;
                    margin-left: 2px;
                    background: url(../../../assets/images/arrow.png) no-repeat;
                    background-size: cover;
                }
            }
            .tab-fixed {
                width: 100%;
                background: #fff;
                box-shadow: 0 0.1rem 0.1rem rgb(0 0 0 / 20%);
                position: fixed;
                top: 0;
            }
        }
        .introduce {
            h2 {
                text-align: center;
                font-size: 0.36rem;
                margin-top: 0.3rem;
            }
            .line {
                color: #c0c0c0;
                font-size: 0.3rem;
                margin: 10px auto;
                text-align: center;
            }
            .line::before {
                content: '';
                display: inline-block;
                width: 15%;
                border-top: 1px solid #c0c0c0;
                vertical-align: middle;
                margin-right: 10px;
            }
            .line::after {
                content: '';
                display: inline-block;
                width: 15%;
                border-top: 1px solid #c0c0c0;
                vertical-align: middle;
                margin-left: 10px;
            }
        }
        .introduce-html {
            padding: 0.2rem;
            font-size: 0.28rem;
        }
        .introduce-more {
            color: #005bac;
            font-size: 0.28rem;
            float: right;
            margin-right: 0.3rem;
        }
        .news-line {
            font-size: .36rem;
            color: #005bac;
            margin: 20px auto;
            margin-top: 50px;
            text-align: center;
        }
        .news-line::before {
            content: '';
            display: inline-block;
            width: 35%;
            border-top: 1px solid #005bac;
            vertical-align: middle;
            margin-right: 10px;
        }
        .news-line::after {
            content: '';
            display: inline-block;
            width: 35%;
            border-top: 1px solid #005bac;
            vertical-align: middle;
            margin-left: 10px;
        }
        .news-list {
            padding: 0.3rem;
            .item {
                display: flex;
                padding-bottom: 0.3rem;
                border-bottom: 1px solid #e1e1e1;
                margin-bottom: 0.3rem;
                .img {
                    width: 2rem;
                    border-radius: 3px;
                    overflow: hidden;
                    height: 1.4rem;
                    text-align: center;
                    img {
                        height: 100%;
                        margin-left: 50%;
                        transform: translate(-50%, 0);
                    }
                }
                .right {
                    display: inline-block;
                    width: calc(100% - 2rem);
                    padding: 0 0.3rem;
                    .title {
                        font-size: 0.28rem;
                        line-height: normal;
                        overflow: hidden;
                        display: -webkit-box;
                        text-overflow: ellipsis;
                        word-break: normal;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        white-space: normal;
                    }
                    .time {
                        font-size: .28rem;
                        color: #c0c0c0;
                        margin-top: 0.3rem;
                    }
                }
                
            }
            .item:last-child {
                border: none;
            }
            .news-more {
                line-height: 0.7rem;
                background: #e8e8e8;
                font-size: 0.28rem;
                color: #666;
                text-align: center;
                border-radius: 2px;
            }
        }
    }
</style>