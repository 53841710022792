import { get } from '@/utils/http'

export const getBanner = (data) => {
    return get('group/getBanner', data)
}

export const getNews = (data) => {
    return get('group/getNews', data)
}

export const getIntroduce = (data) => {
    return get('group/getIntroduce', data)
}